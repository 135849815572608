.section-divider {
    width: 80%;
    border-bottom: 1px solid white;
    margin-bottom: 30px;
}

.section-container {
    width: 100%;
    /* background-color: black; */
    /* background-color: #1a2436; */
    background-color: var(--mediumBackground);
    margin: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

}
.section-balance{
    /* max-width: 45% !important; */
}
.section-menu {
    /* max-width: 15%; */
    /* align-items: flex-start; */
    padding-right: 40px;
    /* justify-content: left; */
    align-items:flex-start;
}

.section-earn {
    /* max-width: 45% !important; */
    height: 100%;
    min-height: 1000px;
    position: absolute;
    /* top: 110px; */
    top:20px;
    right:40px;
    /* z-index: 0; */
    justify-content: right;
}
.section-earn-detail {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.section-dashboard{
    /* max-width: 60% !important; */
    /* margin-right: 70px; */
}
.section-japas {
    /* max-width: 45% !important; */
    margin-left: -40px;
}
.sub-section {
    /* background-color: #161E2D; */
    /* background-color: black !important; */
    padding-top: 20px;
    padding-bottom: 20px;
}
.group {
    width:100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}

.subgroup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}