/*--------------------------------------------------------------
# Buttons
--------------------------------------------------------------*/
.logo-group{
color: var(--darkBackground);
filter: brightness(0);
}
.image-logo{
    /* filter: hue-rotate(300deg); */
    height: 50px;
}
.image-logo-social{
max-height: 30px;
padding-left: 5px;
padding-right: 5px;
margin-left: 5px;
margin-right: 5px;
}
/* .disabled {
    pointer-events: none;
    cursor: none;
    background-color: var(--darkFadedBackground) !important;
} */
.disabled {
    pointer-events: none;
    cursor: none;
    background-color: var(--lightFadedBackground) !important;
}
.readOnly {
    pointer-events: none;
}
.enabled {
    pointer-events: auto;
}
.danger{
    color: palevioletred;
}
.input-group{
    /* background-color:rgb(65, 65, 65); */
    background-color: var(--lightBackground);
    color:black;
    border-radius: 10px;
    border: none;
}
.input-number{
    border-radius: 10px;
    color:black;
    font-size: 18px;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
    border:none;
}
.input-number:focus-within{
border: 2px solid transparent;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.connect-wrapper{
border: 1px solid;
    border-color: var(--mediumBackground);
    background-color: none;
    color: var(--mediumBackground);
    border-radius: 20px;
}
.button-group{
display: flex;
flex-direction: row;
margin-left: 10px;
margin-right: 10px;
}
.btn-outline {
    display: flex;
    min-width: 200px;
    border: 1px solid;
    border-color: #2fbbd4;
    background-color: none;
    color: #2fbbd4;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 700;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
    text-decoration: none;
    justify-content: center;
    align-items: center;
}
.btn-outline:hover {
    color: #1a2436;
    background-color: #b5e0d9;
}
.btn-outline-dark {
    display: flex;
    min-width: 100px;
    border: 1px solid;
    border-color: var(--mediumBackground);
    background-color: none;
    color: var(--mediumBackground);
border-radius: 20px;
    font-size: 18px;
    font-weight: 700;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
    text-decoration: none;
    justify-content: center;
    align-items: center;
}

.btn-outline-dark:hover {
    color:var(--lightBackground);
    background-color: var(--lightFadedBackground);
    text-decoration: none;
}
.btn-text{
    display: flex;
        min-width: 100px;
        padding:0px;
        /* border-color: #2fbbd4; */
        background-color: none;
        color:lightgray;
        /* border-radius: 10px; */
        font-size: 18px;
        margin-left: 5px;
        margin-right: 5px;
        text-decoration: none;
        justify-content: center;
        align-items: center;
}
.btn-text:hover{
    color: #2fbbd4;
        /* border-bottom: 1px solid #2fbbd4; */
}
.btn-underline{
    color: lightgray;
    border-bottom: 1px solid #2fbbd4;
}
.btn-underline:hover{
    color: #2fbbd4;
}
.highlight{
    color: var(--text-highlight);
    margin-top: 0;
    font-weight: 700;
}
.light{
    color: var(--text-light);
}
.dark{
    color: var(--mediumBackground);
}
.heading{
    font-weight: 500;
    margin-top: 20px;

            margin-bottom: 20px;
            text-align: center;
        
}
.underline{
    text-decoration: underline;
    text-decoration-color: #2fbbd4;
}
.double-line{
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #2fbbd4;
    border-bottom: 1px solid #2fbbd4;
}
.my-align-center{
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
}

.justify-between{
    justify-content: space-between !important;
    padding-left: 20px;
    padding-right: 20px;
}
.justify-evenly{
    justify-content:space-evenly !important;
}
.justify-left{
justify-content:left !important;
}
.justify-self-right {
    justify-self: right !important;
}
.align-center{
    align-items: center !important;
}
.align-bottom {
    align-items: flex-end !important;
}
/*--------------------------------------------------------------
# Containers
--------------------------------------------------------------*/
.base-container {
    /* width: 100%; */
    position: relative;
    padding-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 20px;
    min-height: 1050px;
    /* border: solid 2px white; */
}

.header-container {
    /* width: 100%; */
   /* background-color: #1a2436; */
/* background-color: var(--mediumBackground); */
   color:white;
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 0px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 20px;
}

.row {
    width: 100%;
    /* display: flex; */
    /* flex-direction: row; */
    justify-content: space-between;
    /* align-items: center; */
    /* background-color: black; */
}

.col {
    /* min-height: 500px; */
    /* overflow-y: hidden; */
    /* color:white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; */
}