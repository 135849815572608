.menu-section{

}
.menu-group{
    display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
}
.menu-item{
padding: 10px;
}
.item-link{
/* color:white; */
padding-top: 5px;
padding-bottom: 5px;
}

.item-link:hover{
    border-top: 1px solid #2fbbd4;
    border-bottom: 1px solid #2fbbd4;
    text-decoration: none;
    color: #2fbbd4;
}

.active {
    border-top: 1px solid #2fbbd4;
    border-bottom: 1px solid #2fbbd4;
    text-decoration: none;
    color: #2fbbd4;
}