@import url('https://fonts.googleapis.com/css2?family=Marcellus&family=Protest+Guerrilla&family=Roboto:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300..700&display=swap');
@import 'bootstrap/dist/css/bootstrap.min.css';

.protest-guerrilla-regular {
  font-family: "Protest Guerrilla", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.marcellus-regular {
  font-family: "Marcellus", serif;
  font-weight: 400;
  font-style: normal;
}
.teko-bold {
  font-family: "Teko", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  line-height: 30px;
  display: inline-block;
  transform: scaleY(1.5);
  letter-spacing: 1px;
}
/* #root{
  display: flex;
  flex-direction: column;
} */
:root{
  --lightBackground : #a1c4e4;
  --lightFadedBackground: #677e94;
  --mediumBackground: #0d3050;
  --darkBackground: #1a2436;
  --darkFadedBackground: #2c3444;
  --text-highlight:#2fbbd4;
  --text-light: hsla(var(--lightAccent-hsl), 1);
}
body {
  margin: 0;
  /* background-color: #1a2436; */
  /* background-color: #2c3444; */
  background-color: var(--lightBackground);
  /* background-color: white; */
  color: white;
  /* color: #1a2436; */
  font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
